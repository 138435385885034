const areaID = localStorage.getItem("areaID")
  ? localStorage.getItem("areaID")
  : 1;
// const kioskID = globalData.computer
//   ? globalData.computer
//   : 'unknown';

export const setupVars = {
  /******************SETUP*****************/
  setup: {
    kioskID: "mobile-web",
    areaID: areaID,
    locationID: 777000 /**DONOT USE use areaID insteaed**/,
    apiUrl: "https://canonrsna.com/2021/api",
    qrCodeURL: "https://canonrsna.com/VirtualReception/followup",
    contactType: {
      group: "group",
      part: "participant",
      dir: "directions",
    },
    pageTimeOut: 5 * 60 * 1000,
    pageTimeOutEndingAnimation: 15000 /*must be less than pageTimeOut*/,
    cacheTimeOut: 5 * 60 * 1000,
  },
};
