import { kioskVars, getObjKey } from "../config/KioskVars";
import { useNavigate, useParams } from "react-router-dom";
import { Block, TopNav, Header } from "../components/Block";
import { ShowFloorBlock } from "../components/ShowFloorBlock";
import { useState, useEffect } from "react";

import showroom_floor from "../misc_assets/showroom_floor_1.png";

const [svgWidth, svgHeight] = [1200, 750];
function ConferenceRooms() {
  const navigate = useNavigate();
  const [selectedAreaID, setSelectedAreaID] = useState(
    localStorage.getItem("areaID")
      ? JSON.parse(localStorage.getItem("areaID"))
      : null
  );
  useEffect(() => {
    if (!selectedAreaID) {
      navigate("/");
    }
  }, []);

  return (
    <div className="wrapper" style={{ opacity: 1 }}>
      <TopNav back={kioskVars.conferenceHead.back} />
      <main>
        <Header
          content={kioskVars.conferenceHead.title}
          subContent={
            <em className="subhead">
              {kioskVars.conferenceHead.subTitle}
              {kioskVars.conferenceHead.subTitle2.replace(
                "__ICON__",
                kioskVars.misc.emojis_icons.youAreHere
              )}
            </em>
          }
        />
        <section className="outerShell">
          <ShowFloorBlock
            coordinates={
              kioskVars.conferenceRoomsCoordinates[kioskVars.setup.areaID]
            }
            endPointClass="ConferenceRooms"
          />
        </section>
      </main>
    </div>
  );
}

export default ConferenceRooms;
