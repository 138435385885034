import { useParams, useNavigate, useLocation } from "react-router-dom";

import { kioskVars, getObjKey } from "../config/KioskVars";
import { useContact } from "../components/Hooks";
import { TopNav, Header, makeqrcodeURI } from "../components/Block";
import { ThankYou, MessageForm } from "../components/Form";
import { useEffect, useState } from "react";

function Contact({ globalData, setGlobalData, back, cleanUpGlobalData }) {
  const { typeFlag, contactID, regionIDBack, test } = useParams();
  const [
    inputFields,
    inputs,
    setInputs,
    inputsSet,
    keyboardShowHide,
    setKeyboardShowHide,
    outputConfirmation,
    setOutputConfirmation,
    layoutName,
    setLayoutName,
    inputName,
    setInputName,
    shiftedPressed,
    setShiftedPressed,
    keyboard,
    onChangeAll,
    onKeyPress,
    onChangeInput,
    handleShift,
    getInputValue,
    clearInputs,
    outputKeyboard,
    funcs,
  ] = useContact();

  /***REPLACING GLOBDATA WITH USE LOCATION GLOBAL DATA BECAUSE OF REFRESH ISSUES***/
  const location = useLocation();
  const globalDataLOC = location.state;
  // console.log(globalDataLOC.gData, "** CONTACT:globalData **");
  /********************************************************************************/
  const navigate = useNavigate();
  const [selectedAreaID, setSelectedAreaID] = useState(
    localStorage.getItem("areaID")
      ? JSON.parse(localStorage.getItem("areaID"))
      : null
  );
  useEffect(() => {
    if (!selectedAreaID || !globalDataLOC) {
      navigate("/");
    }
  }, []);

  let [dbID, contentHead, contentSubHead, apiFlag] = [
    contactID,
    kioskVars.contactHead.title,
    kioskVars.contactHead.subTitle,
    "group",
  ];

  ///if (globalDataLOC)  is a FIX FOR WRONG LINKS / HELPPING WITH REDIRECT TO HOME
  if (globalDataLOC) {
    switch (typeFlag) {
      case kioskVars.setup.contactType.part:
        if (back === "meeting") {
          apiFlag = "meeting";
          contentHead = kioskVars.contactHead.titleMeeting.replace(
            "__REP__",
            `${globalDataLOC.gData.first_name}  ${globalDataLOC.gData.last_name}`
          );
          contentSubHead = kioskVars.contactHead.subTitleMeeting
            .replace(
              "__REP__",
              `${globalDataLOC.gData.first_name} ${globalDataLOC.gData.last_name}`
            )
            .replace("__LOC__", `  Kiosk Station #${kioskVars.setup.areaID}`);
        } else {
          apiFlag = "individual";
          contentHead = kioskVars.contactHead.title.replace(
            "__REP__",
            `${globalDataLOC.gData.first_name} ${globalDataLOC.gData.last_name}`
          );
          contentSubHead = kioskVars.contactHead.subTitle
            .replace(
              "__REP__",
              `${globalDataLOC.gData.first_name} ${globalDataLOC.gData.last_name}`
            )
            .replace("__LOC__", `  Kiosk Station #${kioskVars.setup.areaID}`);
        }
        dbID = globalDataLOC.gData.id;
        back = "/" + back;
        back =
          globalDataLOC.regionBack === "/findrep" ? "/findrep/byRegion" : back;
        back =
          globalDataLOC.regionBack === "/gethelp"
            ? "/findrep/byRegionHelp"
            : back;
        break;
      case kioskVars.setup.contactType.group:
        if (
          globalDataLOC.gData.link === "AM" ||
          globalDataLOC.gData.link === "other/group/AM"
        ) {
          contentHead = kioskVars.contactHead.title.replace(
            "__REP__",
            "a Canon Ambassador"
          );
          contentSubHead = kioskVars.contactHead.subTitle.replace(
            "__REP__",
            `a Canon Ambassador`
          );
        } else if (
          globalDataLOC.gData.link === "IA" ||
          globalDataLOC.gData.link === "other/group/IA"
        ) {
          contentHead = kioskVars.contactHead.title.replace(
            "__REP__",
            "an International Ambassador"
          );
          contentSubHead = kioskVars.contactHead.subTitle.replace(
            "__REP__",
            `an International Ambassador`
          );
        } else {
          contentHead = kioskVars.contactHead.title.replace(
            "__REP__",
            `Canon ${globalDataLOC.gData.heading} Rep.`
          );
          contentSubHead = kioskVars.contactHead.subTitle.replace(
            "__REP__",
            `a Canon ${globalDataLOC.gData.heading} Rep.`
          );
        }
        contentSubHead = contentSubHead.replace(
          "__LOC__",
          ` Kiosk Station #${kioskVars.setup.areaID}`
        );

        back = contactID === "ambassador" ? "/launch" : "/products";
        back =
          globalDataLOC.gData.link === "other/group/AM" ? "/gethelp" : back;
        break;
      default:
    }
  }
  const contactHeaderView = () => {
    if (outputConfirmation) {
      const subContent =
        back === "/meeting" ? (
          <em className="subhead">
            {kioskVars.contactHead.thankYouMeeting.replace(
              "__REP__",
              `${globalDataLOC.gData.first_name} ${globalDataLOC.gData.last_name}`
            )}
            <br />
            {kioskVars.contactHead.thankYouMeeting2.replace(
              "__LOC__",
              ` Kiosk Station #${kioskVars.setup.areaID}`
            )}
          </em>
        ) : (
          <em className="subhead">{kioskVars.contactHead.thankYouLong}</em>
        );
      return (
        <Header
          content={kioskVars.contactHead.thankYouHead}
          subContent={subContent}
        />
      );
    } else {
      return (
        <Header
          content={contentHead}
          subContent={
            <em>
              {contentSubHead}
              {/*back*/}
            </em>
          }
        />
      );
    }
  };

  const [qrcodeURI, setQRCodeURI] = useState();
  useEffect(() => {
    setQRCodeURI(makeqrcodeURI(12));
  }, []);

  return (
    <div className="wrapper" style={{ opacity: 1 }}>
      <TopNav back={back} />
      <main>
        <div className="modalBlock2">
          {contactHeaderView()}
          <section className={`outerShell`}>
            {outputConfirmation ? (
              <ThankYou url={qrcodeURI} backFlag={back} />
            ) : (
              <MessageForm
                funcs={funcs}
                inputs={inputs}
                vars={{
                  type: typeFlag,
                  id: dbID,
                  apiSetup: kioskVars.setup,
                  apiFlag: apiFlag,
                  qrcodeURI: qrcodeURI,
                }}
              />
            )}
            {/**/}
          </section>
        </div>
      </main>
    </div>
  );
}

export default Contact;
