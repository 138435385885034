import { useNavigate } from "react-router-dom";

import { kioskVars } from "../config/KioskVars";
import backImg from "../misc_assets/back_arrow.png";
import startOverImg from "../misc_assets/start_over.png";
import logoImg from "../misc_assets/canon_logos/canon@0.25x.png";
import logoImg2 from "../misc_assets/canon_logos/canon@0.25x.png";
// import flags from "../components/Flags";
import ad from "../misc_assets/flags/svg/ad.svg";

import ae from "../misc_assets/flags/png100px/ae.png";
import ar from "../misc_assets/flags/png100px/ar.png";
import au from "../misc_assets/flags/png100px/au.png";
import bd from "../misc_assets/flags/png100px/bd.png";
import be from "../misc_assets/flags/png100px/be.png";
import bh from "../misc_assets/flags/png100px/bh.png";
import bo from "../misc_assets/flags/png100px/bo.png";
import br from "../misc_assets/flags/png100px/br.png";
import ch from "../misc_assets/flags/png100px/ch.png";
import cl from "../misc_assets/flags/png100px/cl.png";
import cn from "../misc_assets/flags/png100px/cn.png";
import co from "../misc_assets/flags/png100px/co.png";
import cz from "../misc_assets/flags/png100px/cz.png";
import de from "../misc_assets/flags/png100px/de.png";
import dz from "../misc_assets/flags/png100px/dz.png";
import ec from "../misc_assets/flags/png100px/ec.png";
import eg from "../misc_assets/flags/png100px/eg.png";
import es from "../misc_assets/flags/png100px/es.png";
import et from "../misc_assets/flags/png100px/et.png";
import fi from "../misc_assets/flags/png100px/fi.png";
import fr from "../misc_assets/flags/png100px/fr.png";
import gb from "../misc_assets/flags/png100px/gb.png";
import ge from "../misc_assets/flags/png100px/ge.png";
import gh from "../misc_assets/flags/png100px/gh.png";
import gt from "../misc_assets/flags/png100px/gt.png";
import hk from "../misc_assets/flags/png100px/hk.png";
import hn from "../misc_assets/flags/png100px/hn.png";
import hu from "../misc_assets/flags/png100px/hu.png";
import in_ from "../misc_assets/flags/png100px/in_.png";
import ir from "../misc_assets/flags/png100px/ir.png";
import is from "../misc_assets/flags/png100px/is.png";
import it from "../misc_assets/flags/png100px/it.png";
import jo from "../misc_assets/flags/png100px/jo.png";
import jp from "../misc_assets/flags/png100px/jp.png";
import ke from "../misc_assets/flags/png100px/ke.png";
import kr from "../misc_assets/flags/png100px/kr.png";
import kz from "../misc_assets/flags/png100px/kz.png";
import lb from "../misc_assets/flags/png100px/lb.png";
import lk from "../misc_assets/flags/png100px/lk.png";
import ma from "../misc_assets/flags/png100px/ma.png";
import mn from "../misc_assets/flags/png100px/mn.png";
import mx from "../misc_assets/flags/png100px/mx.png";
import my from "../misc_assets/flags/png100px/my.png";
import ng from "../misc_assets/flags/png100px/ng.png";
import ni from "../misc_assets/flags/png100px/ni.png";
import nl from "../misc_assets/flags/png100px/nl.png";
import no from "../misc_assets/flags/png100px/no.png";
import np from "../misc_assets/flags/png100px/np.png";
import nz from "../misc_assets/flags/png100px/nz.png";
import om from "../misc_assets/flags/png100px/om.png";
import pa from "../misc_assets/flags/png100px/pa.png";
import pe from "../misc_assets/flags/png100px/pe.png";
import ph from "../misc_assets/flags/png100px/ph.png";
import pk from "../misc_assets/flags/png100px/pk.png";
import pl from "../misc_assets/flags/png100px/pl.png";
import pt from "../misc_assets/flags/png100px/pt.png";
import py from "../misc_assets/flags/png100px/py.png";
import qa from "../misc_assets/flags/png100px/qa.png";
import ru from "../misc_assets/flags/png100px/ru.png";
import sa from "../misc_assets/flags/png100px/sa.png";
import se from "../misc_assets/flags/png100px/se.png";
import sg from "../misc_assets/flags/png100px/sg.png";
import sv from "../misc_assets/flags/png100px/sv.png";
import th from "../misc_assets/flags/png100px/th.png";
import tn from "../misc_assets/flags/png100px/tn.png";
import tr from "../misc_assets/flags/png100px/tr.png";
import tt from "../misc_assets/flags/png100px/tt.png";
import tw from "../misc_assets/flags/png100px/tw.png";
import uy from "../misc_assets/flags/png100px/uy.png";
import ve from "../misc_assets/flags/png100px/ve.png";
import vn from "../misc_assets/flags/png100px/vn.png";

import grsa from "../misc_assets/flags/png100px/grsa.png";
import grca from "../misc_assets/flags/png100px/grca.png";
import grot from "../misc_assets/flags/png100px/grot.png";

import us from "../misc_assets/flags/png100px/us.png";
import pr from "../misc_assets/flags/png100px/pr.png";
import ca from "../misc_assets/flags/png100px/ca.png";
import dk from "../misc_assets/flags/png100px/dk.png";

import more from "../misc_assets/flags/svg/more.svg";

// console.log("__________-++++++", us);
const flags = {
  grsa,
  grca,
  grot,
  dk,
  ca,
  pr,
  us,
  more,
  ae,
  ar,
  au,
  bd,
  be,
  bh,
  bo,
  br,
  ch,
  cl,
  cn,
  co,
  cz,
  de,
  dz,
  ec,
  eg,
  es,
  et,
  fi,
  fr,
  gb,
  ge,
  gh,
  gt,
  hk,
  hn,
  hu,
  in_,
  ir,
  is,
  it,
  jo,
  jp,
  ke,
  kr,
  kz,
  lb,
  lk,
  ma,
  mn,
  mx,
  my,
  ng,
  ni,
  nl,
  no,
  np,
  nz,
  om,
  pa,
  pe,
  ph,
  pk,
  pl,
  pt,
  py,
  qa,
  ru,
  sa,
  se,
  sg,
  sv,
  th,
  tn,
  tr,
  tt,
  tw,
  uy,
  ve,
  vn,
};

export const Block = ({ vars, blockClass, prelink, handleClickPageBlock }) => {
  return (
    <nav className={`${blockClass}`} onClick={() => handleClickPageBlock(vars)}>
      <p>{vars.heading}</p>
      {vars.subheading ? <small>({vars.subheading})</small> : ""}
      {vars.emoji_array ? (
        <small className={`flag-emojis`}>
          {vars.emoji_array.map((countryCode) => {
            return <CountryFlag key={countryCode} code={countryCode} />;
          })}
        </small>
      ) : (
        ""
      )}
    </nav>
  );
  /*return (
    <nav className={`${blockClass}`} onClick={() => handleClick()}>
      <p>{vars.heading}</p>
      {vars.subheading ? <small>({vars.subheading})</small> : ""}
      {vars.emoji_array ? (
        <small className={`flag-emojis`}>
          {vars.emoji_array.map((countryCode) => {
            return <CountryFlag key={countryCode} code={countryCode} />;
          })}
        </small>
      ) : (
        ""
      )}
    </nav>
  );*/
};

export const CountryFlag = ({ code, height = "21" }) => {
  // console.log(code, flags);
  return <img className="flag-img" src={flags[code]} height={height} />;
};

export const CountryFlagButton = ({ code, title, onClick }) => {
  const codeToLower = code ? code.toLowerCase() : "";

  return (
    <div className="country-map-button" onClick={() => onClick()}>
      <small className={`flag-emojis`}>
        <CountryFlag code={codeToLower} height="25" />
      </small>
      <br />
      {title}
    </div>
  );
};

export const MiniBlock = ({ vars, blockClass, setClick }) => {
  const handleClick = () => {
    setClick();
    // console.log("MiniBlock");
  };

  return (
    <nav className={`${blockClass}`} onClick={() => handleClick()}>
      <p>{vars.heading}</p>
      {vars.subheading ? <small>({vars.subheading})</small> : ""}
    </nav>
  );
};

export const TopNav = ({ back, shell = false }) => {
  const navigate = useNavigate();
  return shell ? (
    <nav className="topNav">
      <p className="topNavBack"></p>
      <p className="topNavLogo" onClick={() => navigate("/")}>
        <img src={logoImg2} />
      </p>
      <p className="topNavStartover"></p>
    </nav>
  ) : (
    <nav className="topNav">
      <p className="topNavBack" onClick={() => navigate(back)}>
        {back === "/" ? (
          <sub>
            <sub>⎋ Start Over</sub>
          </sub>
        ) : (
          <img src={backImg} />
        )}
      </p>
      <p className="topNavLogo" onClick={() => navigate("/")}>
        <img src={logoImg2} />
      </p>

      {back === "/" ? (
        <p className="topNavStartover">
          <sub>
            <sub>
              <i>@Kiosk #{kioskVars.setup.areaID}</i>
            </sub>
          </sub>
        </p>
      ) : (
        <p className="topNavStartover" onClick={() => navigate("/launch")}>
          <img src={startOverImg} />
        </p>
      )}
    </nav>
  );
};

export const Header = ({ content, subContent, blocks }) => {
  return (
    <header>
      <h1>
        {content}
        <em>{subContent}</em>
      </h1>
      {blocks}
    </header>
  );
};

export const FinishedButton = () => {
  const navigate = useNavigate();
  return (
    <div className="closeOutButt">
      <button className="secondaryButton" onClick={() => navigate("/launch")}>
        Close
      </button>
    </div>
  );
};

/****DIRECTORY****/
export const letterHeading = (p, abcRef, headerClass) => (
  <h3
    ref={(el) => (abcRef.current[p.letter_heading] = el)}
    className={headerClass}
  >
    <span>{p.letter_heading}</span>
  </h3>
);

export const letterRow = (p, headerClass) => (
  <p
    className={"letterRowFlag" /*p.cost_center !== '' ? 'letterRowFlag' : ''*/}
  >
    <span className="fixCase">
      <b>{p.last_name.toLowerCase()}</b>, <i>{p.first_name.toLowerCase()}</i>
    </span>
    {p.cost_center !== "" ? (
      <em>
        <CountryFlag code={p.cost_center} height="28" />
        <br />
        {p.manager_id}
      </em>
    ) : (
      <em>
        <CountryFlag code={p.abrv.toLowerCase()} height="28" />
        <br />
        {p.name}
      </em>
    )}
  </p>
);
export const scrollDown = (ref, abcRef, directoryRef) => {
  directoryRef.current.scrollTo({
    top: abcRef.current[ref].offsetTop - 300,
    behavior: "smooth",
  });
  // console.log(abcRef.current["B"]);
};
export const mapView = (
  participants,
  regions,
  dataType,
  setRegionsDisplay,
  setSelectedRegion,
  setRegionsFlagDisplay
) => {
  const regionSorted = Object.values(regions).sort(function (a, b) {
    if (a.name === "Other") return 1;
    return ("" + a.name).localeCompare(b.name);
  });

  return (
    <article className="country-map">
      {regionSorted.map((r, k) =>
        participants.some((s) =>
          dataType === "region" ? r.regionid === s.regionid : true
        ) ? (
          <CountryFlagButton
            key={r.regionid}
            code={r.abrv}
            title={r.name}
            onClick={() => {
              setRegionsDisplay(r.name);
              setSelectedRegion(r.regionid);
              setRegionsFlagDisplay(r.abrv ? r.abrv.toLowerCase() : "us");
            }}
          />
        ) : (
          ""
        )
      )}

      <div className={"region-spacer"}> </div>
    </article>
  );
};

export function makeqrcodeURI(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
