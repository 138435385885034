import { kioskVars, getObjKey } from "../config/KioskVars";
import { useParams, useNavigate } from "react-router-dom";
import { Block, TopNav, Header } from "../components/Block";
import { ShowFloorBlock } from "../components/ShowFloorBlock";
import { useEffect, useState } from "react";
import axios from "axios";

import showroom_floor from "../misc_assets/showroom_floor_3.png";

const [svgWidth, svgHeight] = [1200, 750];
function Status() {
  const { customerURI } = useParams();
  // const directionVars = getObjKey(kioskVars.demoBlocks, locationID);
  const [customer, setCustomer] = useState();
  useEffect(() => {
    const config = {
      method: "post",
      url: `${kioskVars.setup.apiUrl}/getCustomers`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: { cust_uri: customerURI },
    };

    const sendPostRequest = async () => {
      try {
        const resp = await axios(config);
        const cusData = Object.values(resp.data.results);
        setCustomer(cusData[0]);
        // console.log("--->>>>>>>> cusData ", cusData[0]);
      } catch (err) {
        // Handle Error Here
        // console.error(err);
        // handleKioskError(err);
      }
    };

    sendPostRequest();
  }, []);

  // console.log(
  //   "--->",
  //   customer?.delivery_people
  //     ? Object.values(customer.delivery_people).map((x) => x.last_name)
  //     : "--"
  // );
  const navigate = useNavigate();

  return (
    <div className="wrapper" style={{ opacity: 1 }}>
      <TopNav back="/" shell={true} />
      <main>
        <div className="modalBlock2">
          <Header
            content="Notification Status"
            subContent="See below for status updates on this notification request."
          />
          <br />
          <br />
          <br />
          <section className={`outerShell`}>
            <h1 className="thankyou">
              <div className="tyP1">
                Notification Sent:
                <p className="tyP1a">
                  Please wait at Kiosk Station #
                  {customer?.akid ? customer.akid : "..."}
                </p>
                <div className="tyP1b">
                  <span>
                    <table className="notification-status-table">
                      <tbody>
                        <tr>
                          <th colSpan={2} style={{ textAlign: "center" }}>
                            <b>STATUS:</b>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <b>FROM:</b>
                          </th>
                          <td className="fixCase">
                            {customer?.cusID
                              ? `${customer.customer_fname} ${customer.customer_lname}`
                              : "..."}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <b>TO:</b>
                          </th>
                          <td>
                            {customer?.mrid && customer.mrid > 0 ? (
                              <div>{customer.name_modality}</div>
                            ) : (
                              ""
                            )}
                            {customer?.apid && customer.apid > 0 ? (
                              <div>Canon Ambassador</div>
                            ) : (
                              ""
                            )}

                            {customer?.delivery_people
                              ? Object.values(customer.delivery_people).map(
                                  (delivery_person) => {
                                    const del_per_date = new Date(
                                      delivery_person.timestamp
                                    )
                                      .toLocaleString("us", {
                                        timeZone: "Asia/Vladivostok",
                                      })
                                      .split(" ");
                                    //mrid

                                    return (
                                      <div key={delivery_person.id}>
                                        <i>
                                          {delivery_person.first_name}{" "}
                                          {delivery_person.last_name}{" "}
                                        </i>
                                        <span>
                                          ⇢ {delivery_person.delivery_status}-
                                          {del_per_date[1]}
                                          {del_per_date[2]}
                                        </span>
                                      </div>
                                    );
                                  }
                                )
                              : "..."}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <button
                      style={{ width: "30%" }}
                      className="primaryButton"
                      onClick={() => {
                        navigate("/launch");
                      }}
                    >
                      Start <br />
                      Over
                    </button>
                    <button
                      style={{ width: "30%" }}
                      className="primaryButtonAlt"
                      onClick={() => {
                        window.location.reload(false);
                      }}
                    >
                      Update
                      <br />
                      Status
                    </button>
                    <br />
                    <button
                      style={{ width: "30%", marginTop: "5px" }}
                      className="secondaryButton"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Close
                    </button>
                  </span>
                </div>
              </div>
            </h1>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Status;
