import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { kioskVars } from "../config/KioskVars";

import { Dropdown } from "../components/Form";
import {
  TopNav,
  Header,
  CountryFlagButton,
  CountryFlag,
  Block,
  letterHeading,
  letterRow,
  scrollDown,
  mapView,
} from "../components/Block";
import { useParticipant, useRegions } from "../components/Hooks";

export function Directory({
  globalData,
  setGlobalData,
  cleanUpGlobalData,
  title,
  back,
  prelink,
  dataType,
}) {
  const navigate = useNavigate();
  const [selectedAreaID, setSelectedAreaID] = useState(
    localStorage.getItem("areaID")
      ? JSON.parse(localStorage.getItem("areaID"))
      : null
  );
  useEffect(() => {
    if (!selectedAreaID) {
      navigate("/");
    }
  }, []);

  const [participants, setParticipants] = useState([]);
  const getParticipants = useParticipant(setParticipants);

  const [selectedRegion, setSelectedRegion] = useState(); //'120'
  const [regions, setRegions] = useState([]);
  const [regionDisplay, setRegionsDisplay] = useState(); //
  const [regionFlagDisplay, setRegionsFlagDisplay] = useState(); //
  const getRegions = useRegions(setRegions);

  // console.log("globalData", globalData);

  useEffect(() => {
    getRegions();
    getParticipants();
  }, []);

  useEffect(() => {
    setGlobalData({ ...globalData, cleanUpGlobalData });
  }, [cleanUpGlobalData.flag]);

  const abcRef = useRef([]);
  const directoryRef = useRef();

  // const navigate = useNavigate();
  const handleClick = (part) => {
    if (part.id) {
      let stateGlobLOC;
      switch (dataType) {
        case "region":
          setGlobalData({
            ...globalData,
            ["gData"]: part,
            ["regionBack"]: back,
          });
          stateGlobLOC = {
            ...globalData,
            ["gData"]: part,
            ["regionBack"]: back,
          };
          break;
        default:
          setGlobalData({ ...globalData, ["gData"]: part });
          stateGlobLOC = { ...globalData, ["gData"]: part };
      }

      navigate(
        "/" + prelink + "/" + kioskVars.setup.contactType.part + "/" + part.id,
        {
          state: stateGlobLOC,
        }
      );
    }
    // console.log('clicked id', part);
  };

  const regionArrayFilter = (f) =>
    dataType === "region"
      ? f.territory_id === selectedRegion || f.letter_heading
      : true;

  const regionFilterCount =
    parseInt(participants.filter(regionArrayFilter).length) -
    parseInt(kioskVars.misc.alphabet.length);

  const regionArraySome = (f) =>
    dataType === "region" ? f.territory_id === selectedRegion : true;

  const letterStartsWithArraySome = function (f) {
    return (
      (f.last_name &&
        f.last_name.toUpperCase().charAt(0) === this.toUpperCase()) ||
      false
    );
  };

  const directoryView = () => {
    const defaultDirectoryView = () => {
      return (
        <article className="directory">
          {regionFilterCount > kioskVars.misc.min_count_to_show_letters ? (
            <aside>
              {kioskVars.misc.alphabet.map((a, k) => {
                return (
                  <div
                    key={k}
                    onClick={() => scrollDown(a, abcRef, directoryRef)}
                    className={
                      participants
                        .filter(regionArrayFilter)
                        .some(letterStartsWithArraySome, a)
                        ? ""
                        : "no-results-letter-shortcut"
                    }
                  >
                    {a}
                  </div>
                );
              })}
            </aside>
          ) : (
            ""
          )}
          <section>
            <div ref={directoryRef}>
              {participants.filter(regionArrayFilter).map((p, k) =>
                p.letter_heading ? (
                  regionFilterCount >
                  kioskVars.misc.min_count_to_show_letters ? (
                    <nav key={k} onClick={() => handleClick(p)}>
                      {p.letter_heading
                        ? letterHeading(
                            p,
                            abcRef,
                            participants
                              .filter(regionArrayFilter)
                              .some(letterStartsWithArraySome, p.letter_heading)
                              ? ""
                              : "no-results-letter-shortcut"
                          )
                        : letterRow(p)}
                    </nav>
                  ) : (
                    ""
                  )
                ) : (
                  <nav key={k} onClick={() => handleClick(p)}>
                    {p.letter_heading
                      ? letterHeading(
                          p,
                          abcRef,
                          participants
                            .filter(regionArrayFilter)
                            .some(letterStartsWithArraySome, p.letter_heading)
                            ? ""
                            : "no-results-letter-shortcut"
                        )
                      : letterRow(p)}
                  </nav>
                )
              )}
              <hr className="directory-bottom-spacer" />
            </div>
          </section>
        </article>
      );
    };
    const altDirectoryView = () => {
      return (
        <header
          className="directoryHeaderBlock"
          /*style={{
            border: 'solid 1px rgba(255,255,255, 0.91)',
            height: '50vh',
          }}*/
        >
          {participants.filter(regionArrayFilter).map((p, k) =>
            p.letter_heading ? (
              regionFilterCount > kioskVars.misc.min_count_to_show_letters ? (
                <nav key={k} onClick={() => handleClick(p)}>
                  {p.letter_heading ? letterHeading(p) : letterRow(p)}
                </nav>
              ) : (
                ""
              )
            ) : (
              <nav
                className="square_mini"
                key={k}
                onClick={() => handleClick(p)}
              >
                <p className="directoryBlock">
                  {p.first_name} {p.last_name}
                </p>
                <small>({regionDisplay})</small>
              </nav>
            )
          )}
        </header>
      );
    };
    return defaultDirectoryView();
    // return regionFilterCount > kioskVars.misc.min_count_to_show_letters
    //   ? defaultDirectoryView()
    //   : altDirectoryView();
  };

  const regionBackButton = () => {
    return (
      <div className="region-back-button">
        <button
          className="secondaryButton"
          onClick={() => {
            setSelectedRegion();
            setRegionsDisplay();
          }}
        >
          Back to Countries
        </button>
      </div>
    );
  };
  const titleContentHeader = () => {
    if (dataType === "region") {
      if (regionDisplay) {
        return kioskVars.nameregionHead.titleRegion2;
      } else {
        return kioskVars.nameregionHead.titleRegion;
      }
    } else {
      return title;
    }
  };
  const subContentHeader = () => {
    switch (dataType) {
      case "participant":
        return <em>{kioskVars.nameregionHead.subTitle}</em>;
        break;
      case "region":
        return regionDisplay ? (
          <em className="subheadLRG">
            <CountryFlag code={regionFlagDisplay} height="22" /> {regionDisplay}
          </em>
        ) : (
          <em>Select a Country/Region</em>
        );
        break;
      default:
        return <em>{kioskVars.meetingHead.subTitle}</em>;
    }
  };
  const contentDisplay = () => {
    switch (dataType) {
      case "participant":
        return directoryView(
          participants,
          regionDisplay,
          regionFilterCount,
          regionArrayFilter,
          abcRef,
          directoryRef,
          handleClick
        );
        break;
      case "region":
        return regionDisplay
          ? directoryView(
              participants,
              regionDisplay,
              regionFilterCount,
              regionArrayFilter,
              abcRef,
              directoryRef,
              handleClick
            )
          : mapView(
              participants,
              regions,
              dataType,
              setRegionsDisplay,
              setSelectedRegion,
              setRegionsFlagDisplay
            );
        break;
      default:
        return directoryView();
    }
  };
  // console.log("-dataType-->", dataType, selectedRegion, regionFlagDisplay);

  return (
    <div className="wrapper" style={{ opacity: 1 }}>
      <TopNav back={back} />
      <main className={participants.length === 0 ? `loading` : ""}>
        <Header
          content={titleContentHeader()}
          subContent={subContentHeader()}
        />
        <section className="outerShell">
          {contentDisplay()}
          {dataType === "region" && regionDisplay ? regionBackButton() : ""}
        </section>
      </main>
      {/*globalData.computer*/}
    </div>
  );
}
