import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { kioskVars } from "../app/config/KioskVars";
import Home from "../app/pages/Home";
import PageBlock from "../app/components/PageBlock";
import { Directory } from "../app/components/Directory";

import Directions from "../app/pages/Directions";
import Contact from "../app/pages/Contact";
import ConferenceRooms from "../app/pages/ConferenceRooms";
import Status from "../app/pages/Status";
import Offline from "../app/pages/Offline";
// import NameRegion from "../app/pages/NameRegion";
// import KioskError from "../pages/KioskError";
// import KioskSetup from "../app/pages/KioskSetup";

import "../app/css/Kiosk_Baseline.css";
import "../app/css/Kiosk.css";
import "../app/css/Kiosk_Details.css";
import "../app/css/Kiosk_Desktop.css";

function App({ computer }) {
  const [globalData, setGlobalData] = useState({ globalTimer: Date.now() });
  // console.log("xxx", Math.random(), globalData, computer);

  // <Route path="/" element={<Home />} />

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="launch"
        element={
          <PageBlock
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: null,
              flag: "launch",
              computer: computer,
            }}
            title={kioskVars.launchHead.title}
            blocks={kioskVars.launchBlocks}
            back={kioskVars.launchHead.back}
            prelink=""
            blockClass="square_three_sparce"
          />
        }
      />

      <Route
        path="demo"
        element={
          <PageBlock
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.dir,
              flag: "demo",
            }}
            title={kioskVars.demoHead.title}
            blocks={kioskVars.demoBlocks}
            back={kioskVars.demoHead.back}
            prelink={kioskVars.demoHead.prelink}
            blockClass="square_four_sparce"
          />
        }
      />

      <Route path="demo/:locationID" element={<Directions />} />
      <Route path="conferencerooms" element={<ConferenceRooms />} />

      <Route
        path="meeting"
        element={
          <Directory
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.part,
              flag: "meeting",
            }}
            title={kioskVars.meetingHead.title}
            back={kioskVars.meetingHead.back}
            prelink={kioskVars.meetingHead.prelink}
          />
        }
      />
      <Route
        path="meeting/:typeFlag/:contactID"
        element={
          <Contact
            globalData={globalData}
            setGlobalData={setGlobalData}
            back="meeting"
          />
        }
      />
      <Route
        path="findrep"
        element={
          <PageBlock
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.part,
              flag: "findrep",
            }}
            title={kioskVars.findrepHead.title}
            subTitle={kioskVars.findrepHead.subTitle}
            blocks={kioskVars.findrepBlocks}
            back={kioskVars.findrepHead.back}
            prelink={kioskVars.findrepHead.prelink}
            blockClass="square_two"
          />
        }
      />
      <Route
        path="findrep/byName"
        element={
          <Directory
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.part,
              flag: "findrep",
            }}
            title={`${kioskVars.nameregionHead.title}`}
            back={kioskVars.nameregionHead.back}
            prelink={kioskVars.nameregionHead.prelink}
            dataType="participant"
          />
        }
      />
      <Route
        path="findrep/byRegion/"
        element={
          <Directory
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.part,
              flag: "findrep",
            }}
            title={`${kioskVars.nameregionHead.title}`}
            back={kioskVars.nameregionHead.back}
            prelink={kioskVars.nameregionHead.prelink}
            dataType="region"
          />
        }
      />
      <Route
        path="findrep/byRegionHelp/"
        element={
          <Directory
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.part,
              flag: "findrep",
            }}
            title={`${kioskVars.nameregionHead.title}`}
            back="/gethelp"
            prelink={kioskVars.nameregionHead.prelink}
            dataType="region"
          />
        }
      />
      <Route
        path="findrep/:typeFlag/:contactID/"
        element={
          <Contact
            globalData={globalData}
            setGlobalData={setGlobalData}
            back="findrep"
          />
        }
      />
      <Route
        path="products"
        element={
          <PageBlock
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: kioskVars.setup.contactType.group,
              flag: "products",
            }}
            title={kioskVars.productsHead.title}
            blocks={kioskVars.productsBlocks}
            back={kioskVars.productsHead.back}
            prelink={`${kioskVars.productsHead.prelink}/${kioskVars.setup.contactType.group}`}
            blockClass="square_four_sparce"
          />
        }
      />
      <Route
        path="products/:typeFlag/:contactID"
        element={
          <Contact
            globalData={globalData}
            setGlobalData={setGlobalData}
            back="/"
          />
        }
      />
      <Route
        path="gethelp"
        element={
          <PageBlock
            globalData={globalData}
            setGlobalData={setGlobalData}
            cleanUpGlobalData={{
              gData: null,
              type: null,
              flag: "gethelp",
            }}
            title={kioskVars.gethelpHead.title}
            subTitle={kioskVars.gethelpHead.subTitle}
            blocks={kioskVars.gethelpBlocks}
            back={kioskVars.gethelpHead.back}
            prelink={kioskVars.gethelpHead.prelink}
            blockClass="square_two"
          />
        }
      />

      <Route
        path="other/:typeFlag/:contactID"
        element={
          <Contact
            globalData={globalData}
            setGlobalData={setGlobalData}
            back="/"
          />
        }
      />
      <Route
        path="status/:customerURI"
        element={
          <Status
            globalData={globalData}
            setGlobalData={setGlobalData}
            back="/"
          />
        }
      />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
