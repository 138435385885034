import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { useState, cloneElement, useRef, useEffect } from "react";
import axios from "axios";
import qs from "qs";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { ShowFloorBlock } from "../components/ShowFloorBlock";
import { FinishedButton } from "../components/Block";
import { useKioskError } from "../components/Hooks";
import { kioskVars } from "../config/KioskVars";

export const TextInputField = ({
  field,
  title,
  fullTitle,
  autofocus,
  funcs,
}) => {
  const {
    onChangeInput,
    getInputValue,
    setInputName,
    inputName,
    setKeyboardShowHide,
  } = funcs;

  let otherInputSettings = { required: true };
  if (autofocus) {
    otherInputSettings.autoFocus = true;
  }

  const input = (
    <input
      id={field}
      value={getInputValue(field)}
      onFocus={() => {
        setInputName(field);
      }}
      onBlur={() => {}}
      onChange={onChangeInput}
      placeholder={title}
      type={"text"}
      className={field === inputName ? "inputFocus" : ""}
      {...otherInputSettings}
    />
  );

  return <li>{input}</li>;
};

export const MessageForm = ({ funcs, inputs, vars }) => {
  const { clearInputs, setOutputConfirmation } = funcs;
  const { type, id, apiSetup, apiFlag, qrcodeURI } = vars;
  const { apiUrl, kioskID, areaID } = apiSetup;
  const handleKioskError = useKioskError();

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      type,
      kiosk: kioskID,
      akid: areaID,
      apiFlag: apiFlag,
      customerURI: qrcodeURI,
    };
    let urlRoute;
    switch (type) {
      case kioskVars.setup.contactType.part:
        urlRoute = "sendMessage";
        data.message = "-";
        data.pid = id;
        data.type = 0;
        break;
      case kioskVars.setup.contactType.group:
        urlRoute = "sendGroupMessage";
        data.message = "-";
        data.gid = id;
        data.type = 1;
        break;
      default:
        urlRoute = "kioskError";
        data.message = "Some kind of error";
    }

    const apiMessenger = (data, urlRoute, setOutputConfirmation) => {
      const data_qs = qs.stringify(data);

      const config = {
        method: "post",
        url: `${apiUrl}/${urlRoute}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data_qs,
      };
      // console.log(config);
      const sendPostRequest = async () => {
        try {
          const resp = await axios(config);
          // console.log("**new try**", resp.data, kioskVars);
          // setOutputConfirmation(true);
          setOutputConfirmation(resp.data);
        } catch (err) {
          // Handle Error Here
          // console.log('tyuyui');
          // navigate('/error');
          handleKioskError(err);
          // console.error(err);
        }
      };

      sendPostRequest();
    };
    apiMessenger({ ...data, ...inputs }, urlRoute, setOutputConfirmation);
  };

  return (
    <section className="formShell">
      <form onSubmit={handleSubmit} autoComplete="off">
        <ul>
          <TextInputField
            field="fname"
            title="First Name"
            autofocus={true}
            funcs={funcs}
          />
          <TextInputField field="lname" title="Last Name" funcs={funcs} />
          <TextInputField
            field="company"
            title="Company/Organization"
            funcs={funcs}
          />
          <TextInputField field="email" title="Email" funcs={funcs} />
          <li className="buttonRow">
            <button
              type="reset"
              className="secondaryButton"
              onClick={() => clearInputs()}
            >
              Reset
            </button>
            <button type="submit" className="primaryButton">
              Send
            </button>
          </li>
        </ul>
      </form>
    </section>
  );
};

const [svgWidth, svgHeight] = [1200, 750];
export const ThankYou = ({ url, backFlag }) => {
  const navigate = useNavigate();

  const defaultThankYou = () => {
    return (
      <>
        <h1 className="thankyou">
          <div className="tyP1">
            Notification Sent:
            <p className="tyP1a">
              Please wait near Kiosk Station #{kioskVars.setup.areaID}!
            </p>
            <p className="tyP1b">
              Status:<span></span>
              <button
                type="text"
                className="primaryButton"
                onClick={() => navigate("/status/" + url)}
              >
                View Status
              </button>
            </p>
          </div>
        </h1>
        <FinishedButton />
      </>
    );
  };
  const meetingThankYou = () => {
    return (
      <>
        <h1 className="thankyou">
          <div className="tyP1">
            Meeting Check-in Sent:
            <p className="tyP1a">
              Please wait near Kiosk Station #{kioskVars.setup.areaID}!
            </p>
            <p className="tyP1b">
              Status:<span></span>
              <button
                type="text"
                className="primaryButton"
                onClick={() => navigate("/status/" + url)}
              >
                View Status
              </button>
            </p>
          </div>
        </h1>
        <FinishedButton />
      </>
    );
  };

  const meetingThankYouOLD = () => {
    return (
      <ShowFloorBlock
        coordinates={
          kioskVars.conferenceRoomsCoordinates[kioskVars.setup.areaID]
        }
        endPointClass="ConferenceRooms"
      />
    );
  };
  return backFlag === "/meeting" ? meetingThankYou() : defaultThankYou();
};

export const QRcode = ({ url }) => {
  // value={`${kioskVars.setup.qrCodeURL}/${url}`}

  return (
    <QRCodeCanvas
      id="qrCode"
      value={`https://us.medical.canon/contact/`}
      size={300}
      bgColor={"#000000"}
      fgColor={"#ff0000"}
      level={"H"}
      imageSettings={{
        src:
          "https://www.lamboo-medical.com/wp-content/uploads/2021/05/22020217-Canon-Medical-Group-mark_180420_white-logo-to-Lamboo-for-website-e1645171472806.png",
        height: 72,
        width: 155,
        excavate: true,
      }}
    />
  );
};

export const Dropdown = ({ trigger, menu }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown">
      {cloneElement(trigger, {
        onClick: handleOpen,
      })}
      {open ? (
        <ul className="menu">
          {menu.map((menuItem, index) => (
            <li key={index} className="menu-item">
              {cloneElement(menuItem, {
                onClick: () => {
                  menuItem.props.onClick();
                  setOpen(false);
                },
              })}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
