import { kioskVars } from "../config/KioskVars";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { Block, TopNav, Header } from "./Block";

function PageBlock({
  globalData,
  setGlobalData,
  cleanUpGlobalData,
  title,
  subTitle,
  back,
  prelink,
  blocks,
  blockClass,
}) {
  // console.log("here", globalData);
  // useEffect(() => {
  //   setGlobalData({ ...globalData, ...cleanUpGlobalData });
  // }, [cleanUpGlobalData.flag]);
  //
  // const setGlob = (vars) => {
  //   setGlobalData({
  //     ...globalData,
  //     ["gData"]: vars,
  //     ["type"]: cleanUpGlobalData.type,
  //     ["flag"]: cleanUpGlobalData.flag,
  //   });
  // };
  // console.log("kioskVars", kioskVars);

  const navigate = useNavigate();
  const [selectedAreaID, setSelectedAreaID] = useState(
    localStorage.getItem("areaID")
      ? JSON.parse(localStorage.getItem("areaID"))
      : null
  );
  useEffect(() => {
    if (!selectedAreaID) {
      navigate("/");
    }
  }, []);

  const handleClick = (vars) => {
    const stateGlobLOC = {
      ...globalData,
      ["gData"]: vars,
      ["type"]: cleanUpGlobalData.type,
      ["flag"]: cleanUpGlobalData.flag,
    };

    // console.log(stateGlobLOC, "++++++ pageblock click", vars);
    // setGlob();
    navigate("/" + (prelink ? prelink + "/" : "") + vars.link, {
      state: stateGlobLOC,
    });
  };

  return (
    <div className={`wrapper pageblock`} style={{ opacity: 1 }}>
      <TopNav back={back} />
      <main>
        <Header
          content={title}
          subContent={subTitle}
          blocks={blocks.map((a, k) => {
            return (
              <Block
                key={k}
                vars={a}
                prelink={prelink}
                blockClass={blockClass}
                handleClickPageBlock={handleClick}
              />
            );
          })}
        />
      </main>
    </div>
  );
}

export default PageBlock;
