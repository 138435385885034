import { kioskVars } from "../config/KioskVars";
import { useNavigate } from "react-router-dom";
import logoImg from "../misc_assets/canon_logos/canon@0.33x.png";
import { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

// import KioskError from "./KioskError";

function Home(props) {
  const navigate = useNavigate();
  const handleClick = () => navigate("/launch");
  // const handleClick = () => navigate('/setup');
  const handleClickError = () => navigate("/");

  const [selectedAreaID, setSelectedAreaID] = useState(
    JSON.parse(localStorage.getItem("areaID"))
  );
  useEffect(() => {
    // console.log("selectedAreaID", selectedAreaID);
    localStorage.setItem("areaID", JSON.stringify(selectedAreaID));
  }, [selectedAreaID]);

  const handleChange = (selectedAreaChange) => {
    // console.log("handleChangehandleChange", selectedAreaChange.value);
    setSelectedAreaID(selectedAreaChange.value);
    window.location.reload(false);
  };

  const options = [1, 2, 3, 4, 5, 6].map((m) => {
    return { value: m, label: `Station #${m}` };
  });
  // console.log(
  //   "xxselectedAreaIDx",
  //   selectedAreaID ? `primaryButton` : `secondaryButton`
  // );

  const [serverStatus, setServerStatus] = useState();
  useEffect(() => {
    const config = {
      method: "post",
      url: `${kioskVars.setup.apiUrl}/getServerStatus`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: { test: 1 },
    };

    const sendPostRequest = async () => {
      try {
        const resp = await axios(config);
        const statusData = resp.data.results;
        setServerStatus(statusData);
        // console.log("--->>>>>>>> cusData ", statusData);
      } catch (err) {
        // console.log("error", err);
      }
    };

    sendPostRequest();
  }, []);

  return (
    <div className={`wrapper welcome`} style={{ opacity: 1 }}>
      <main>
        <h1>
          {kioskVars.welcomeHead.title}
          {kioskVars.welcomeHead.title2}
          <div className="modalBlock">
            <br />
            <em>
              <strong>
                Please select the Kiosk Station you are closest to right now.
              </strong>
              <span className="selectArea">
                <Select
                  options={options}
                  onChange={handleChange}
                  selectedValue={selectedAreaID}
                  value={
                    selectedAreaID
                      ? {
                          id: selectedAreaID,
                          label: `I am near Kiosk Station ⇢ #${selectedAreaID}`,
                        }
                      : {
                          id: 1,
                          label: `Please select a Kiosk Station...`,
                        }
                  }
                />
              </span>
            </em>
            {selectedAreaID ? (
              <>
                <button
                  className={
                    selectedAreaID
                      ? `primaryButton welcome`
                      : `disabledButton welcome`
                  }
                  onClick={() => navigate("/launch")}
                  disabled={selectedAreaID ? false : true}
                >
                  Next
                </button>
                <em>
                  <br />
                  {selectedAreaID
                    ? "(Station #" + selectedAreaID + " selected)"
                    : ""}
                </em>
              </>
            ) : (
              ""
            )}
            <br />
            <br />
          </div>
        </h1>
        <h4>
          <span>
            <img className="welcomeLogo" src={logoImg} />
          </span>
        </h4>
      </main>
    </div>
  );
}

export default Home;

/*
<strong>
  <br />
  {serverStatus}
</strong>

*/
