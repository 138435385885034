import { kioskVars } from "../config/KioskVars";
import { useNavigate } from "react-router-dom";
import { FinishedButton } from "../components/Block";
import { useEffect, useState } from "react";
import axios from "axios";

export const ShowFloorBlock = ({ coordinates, endPointClass }) => {
  const navigate = useNavigate();

  return (
    <div className="showroomFloor">
      <div className="directions_wrapper">
        <div className="map"></div>
        <div className={`directions-start loc-start-${kioskVars.setup.areaID}`}>
          <div>
            {kioskVars.misc.emojis_icons.youAreHere}
            <br />
            <sup>#{kioskVars.setup.areaID}</sup>
          </div>
        </div>
        <div className={`directions-end loc-end-${endPointClass}`}>
          <div>{kioskVars.misc.emojis_icons.yourDestination}</div>
        </div>

        <div className={`directions-end-static loc-end-MR`}>
          <div>MR</div>
        </div>
        <div className={`directions-end-static loc-end-CT`}>
          <div>CT</div>
        </div>
        <div className={`directions-end-static loc-end-UL`}>
          <div>UL</div>
        </div>
        <div className={`directions-end-static loc-end-XR`}>
          <div>XR</div>
        </div>
        <div className={`directions-end-static loc-end-IR`}>
          <div>IR</div>
        </div>
        <div className={`directions-end-static loc-end-MI`}>
          <div>MI</div>
        </div>
        <div className={`directions-end-static loc-end-HIT`}>
          <div>HIT</div>
        </div>
        <div className={`directions-end-static loc-end-SE`}>
          <div>SE</div>
        </div>
      </div>
      <FinishedButton />
    </div>
  );
};

/*


        <div className={`directions-end loc-end-MR`}>
          <div>MR</div>
        </div>
        <div className={`directions-end loc-end-CT`}>
          <div>CT</div>
        </div>
        <div className={`directions-end loc-end-UL`}>
          <div>UL</div>
        </div>
        <div className={`directions-end loc-end-XR`}>
          <div>XR</div>
        </div>
        <div className={`directions-end loc-end-IR`}>
          <div>IR</div>
        </div>
        <div className={`directions-end loc-end-MI`}>
          <div>MI</div>
        </div>
        <div className={`directions-end loc-end-HIT`}>
          <div>HIT</div>
        </div>
        <div className={`directions-end loc-end-SE`}>
          <div>SE</div>
        </div>

        <div className={`directions-start loc-start-1`}>
          <div>#1</div>
        </div>
        <div className={`directions-start loc-start-2`}>
          <div>#2</div>
        </div>
        <div className={`directions-start loc-start-3`}>
          <div>#3</div>
        </div>
        <div className={`directions-start loc-start-4`}>
          <div>#4</div>
        </div>
        <div className={`directions-start loc-start-5`}>
          <div>#5</div>
        </div>
        <div className={`directions-start loc-start-6`}>
          <div>#6</div>
        </div>
        <div className={`directions-start loc-start-7`}>
          <div>#7</div>
        </div>


*/
