import { setupVars } from './setupVars';
import { constantVars } from './constantVars';

export const kioskVars = {
  ...setupVars,
  ...constantVars,
};

export function getObjKey(objs, value) {
  let i = 0;
  for (const obj of objs) {
    const k = Object.keys(obj).find((key) => obj[key] === value);
    if (k) {
      return objs[i];
    }
    i++;
  }

  // return Object.keys(obj).find((key) => obj[key] === value);
}
