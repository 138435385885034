import { useRef, useState, useEffect } from "react";
import axios from "axios";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { kioskVars } from "../config/KioskVars";
import { useNavigate } from "react-router-dom";

export function useContact() {
  const inputFields = {
    fname: "",
    lname: "",
    company: "",
    email: "",
  };
  const [inputs, setInputs] = useState(inputFields);
  const inputsSet = [inputs, setInputs];
  const [keyboardShowHide, setKeyboardShowHide] = useState("collapse-hide");
  const [outputConfirmation, setOutputConfirmation] = useState(false);
  /////////  KEYBOARD  ////////////////////////////////////////////////////////////////////////////
  const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("default");
  const [shiftedPressed, setShiftedPressed] = useState(false);
  const keyboard = useRef();

  useEffect(() => {
    // const timer = setTimeout(() => {
    setKeyboardShowHide("reveal-show");
    // }, 1000);
    // return () => clearTimeout(timer);
  }, []);

  const onChangeAll = (inputs) => setInputs({ ...inputs });
  const onKeyPress = (button) => {
    if (shiftedPressed && layoutName === "shift") handleShift();
    if (button === "{shift}" || button === "{lock}") handleShift();
    if (button === "{shift}") setShiftedPressed(true);
    if (shiftedPressed) setShiftedPressed(false);
    if (button === "{exit}") setKeyboardShowHide("collapse-hide");
    // console.log("xxxx", keyboard.current);
  };
  const onChangeInput = (event) => {
    setInputs({ ...inputs, [inputName]: event.target.value });
    // keyboard.current.setInput(event.target.value);
    // console.log("should be changing input", inputs);
  };
  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default";
    setLayoutName(newLayoutName);
  };
  const getInputValue = (inputName) => inputs[inputName] || "";
  const clearInputs = () => {
    setInputs(inputFields);
    // keyboard.current.input = { ...keyboard.current.input, ...inputFields };
  };

  const funcs = {
    onChangeInput,
    getInputValue,
    setInputName,
    inputName,
    setKeyboardShowHide,
    clearInputs,
    setOutputConfirmation,
    keyboard,
  };

  const outputKeyboard = (
    <section className={`${keyboardShowHide} keyboardShell`}>
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        inputName={inputName}
        layoutName={layoutName}
        onChangeAll={onChangeAll}
        onKeyPress={onKeyPress}
        theme={"hg-theme-default myTheme1"}
        layout={{
          default: [
            "{exit} 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} q w e r t y u i o p \\",
            "{lock} a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {shift}",
            ".com @ {space}",
          ],
          shift: [
            "{exit} ! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P |",
            '{lock} A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M < > ? {shift}",
            ".com @ {space}",
          ],
        }}
        display={{
          "{escape}": "esc",
          "{numbers}": "123",
          "{enter}": "enter",
          "{tab}": "tab ⇥",
          "{bksp}": "⌫",
          "{lock}": "caps lock ⇪",
          "{shift}": "⇧",
          "{controlleft}": "ctrl ⌃",
          "{controlright}": "ctrl ⌃",
          "{altleft}": "alt ⌥",
          "{altright}": "alt ⌥",
          "{metaleft}": "cmd ⌘",
          "{metaright}": "cmd ⌘",
          "{space}": " ",
          "{exit}": "▾",
        }}
      />
    </section>
  );

  return [
    inputFields,
    inputs,
    setInputs,
    inputsSet,
    keyboardShowHide,
    setKeyboardShowHide,
    outputConfirmation,
    setOutputConfirmation,
    layoutName,
    setLayoutName,
    inputName,
    setInputName,
    shiftedPressed,
    setShiftedPressed,
    keyboard,
    onChangeAll,
    onKeyPress,
    onChangeInput,
    handleShift,
    getInputValue,
    clearInputs,
    outputKeyboard,
    funcs,
  ];
}

let cache = {};

export function useParticipant(setParticipants) {
  const config = {
    method: "post",
    url: `${kioskVars.setup.apiUrl}/getParticipantsByLastName`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {},
  };

  const sendPostRequest = async () => {
    if (
      Object.keys(cache).length > 0 &&
      Date.now() < cache.timestamp + kioskVars.setup.cacheTimeOut
    ) {
      setParticipants(cache.dbData);
      console.log(
        "using cache data",
        Date.now(),
        Date.now() + kioskVars.setup.cacheTimeOut
      );
    } else {
      try {
        const resp = await axios(config);
        cache = {
          timestamp: Date.now(),
          dbData: Object.values(resp.data.results),
        };
        setParticipants(Object.values(resp.data.results));
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    }
  };

  // console.log("useParticipant");

  return sendPostRequest;
}

let cache_region = {};
export function useRegions(setRegions) {
  const handleKioskError = useKioskError();

  const config = {
    method: "post",
    url: `${kioskVars.setup.apiUrl}/getRegions`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {},
  };

  const sendPostRequest = async () => {
    if (
      Object.keys(cache_region).length > 0 &&
      Date.now() < cache.timestamp + kioskVars.setup.cacheTimeOut
    ) {
      setRegions(cache_region.dbData);
      // console.log(
      //   "using cache_region data",
      //   Date.now(),
      //   Date.now() + kioskVars.setup.cacheTimeOut
      // );
    } else {
      try {
        const resp = await axios(config);
        const rgData = Object.values(resp.data.results);
        const rgDataSorted = rgData; //rgData.sort((a, b) => a.name - b.name);
        cache_region = {
          timestamp: Date.now(),
          dbData: rgDataSorted,
        };
        setRegions(rgDataSorted);
        // console.log("->>>>>>>> rgDataSorted ", rgDataSorted);
      } catch (err) {
        // Handle Error Here
        // console.error(err);
        handleKioskError(err);
      }
    }
  };

  // console.log("useRegions");

  return sendPostRequest;
}

let cache_modality = {}; //ABANDONED FOR NOW - JUST HARD CODE MODALITIES
export function useModality(setModality) {
  const handleKioskError = useKioskError();
  const config = {
    method: "post",
    url: `${kioskVars.setup.apiUrl}/getModality`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {},
  };

  const sendPostRequest = async () => {
    if (
      Object.keys(cache_modality).length > 0 &&
      Date.now() < cache.timestamp + kioskVars.setup.cacheTimeOut
    ) {
      setModality(cache_modality.dbData);
      // console.log(
      //   "using cache_modality data",
      //   Date.now(),
      //   Date.now() + kioskVars.setup.cacheTimeOut
      // );
    } else {
      try {
        const resp = await axios(config);
        cache_modality = {
          timestamp: Date.now(),
          dbData: Object.values(resp.data.results),
        };
        setModality(Object.values(resp.data.results));
        // console.log("->> ", Object.values(resp.data.results));
      } catch (err) {
        // Handle Error Here
        // console.error(err);
        handleKioskError(err);
      }
    }
  };

  // console.log("useRegions");

  return sendPostRequest;
}

export function useKioskTimeout() {
  const [kioskTimerTapTracker, triggerKioskInteraction] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('kioskVars');
    // const timer = setTimeout(() => {
    //   console.log('Timeout called!');
    //   navigate('/');
    // }, kioskVars.setup.pageTimeOut);
    // return () => clearTimeout(timer);
  });

  const kTBRef = useRef();
  const kTBRef2 = useRef();
  const kTBRef3 = useRef();
  const kTBRef4 = useRef();

  useEffect(() => {
    // console.log(
    //   "kioskTimerTapTracker",
    //   kioskTimerTapTracker,
    //   kioskTimerTapTracker % 2 == 0,
    //   kTBRef.current.style.fontSize
    // );
    kTBRef.current.style.animationName = "none";
    kTBRef2.current.style.animationName = "none";
    kTBRef3.current.style.animationName = "none";
    kTBRef4.current.style.animationName = "none";

    const animation = requestAnimationFrame(() => {
      kTBRef.current.style.animationName = "";
      kTBRef.current.style.animationDelay = `${
        kioskVars.setup.pageTimeOut - kioskVars.setup.pageTimeOutEndingAnimation
      }ms`;
      kTBRef.current.style.animationDuration = `${kioskVars.setup.pageTimeOutEndingAnimation}ms`;

      kTBRef2.current.style.animationName = "";
      kTBRef2.current.style.animationDelay = `${
        kioskVars.setup.pageTimeOut - kioskVars.setup.pageTimeOutEndingAnimation
      }ms`;
      kTBRef2.current.style.animationDuration = `${kioskVars.setup.pageTimeOutEndingAnimation}ms`;

      kTBRef3.current.style.animationName = "";
      kTBRef3.current.style.animationDelay = `${
        kioskVars.setup.pageTimeOut - kioskVars.setup.pageTimeOutEndingAnimation
      }ms`;
      kTBRef3.current.style.animationDuration = `${kioskVars.setup.pageTimeOutEndingAnimation}ms`;

      kTBRef4.current.style.animationName = "";
      kTBRef4.current.style.animationDelay = `${
        kioskVars.setup.pageTimeOut - kioskVars.setup.pageTimeOutEndingAnimation
      }ms`;
      kTBRef4.current.style.animationDuration = `${kioskVars.setup.pageTimeOutEndingAnimation}ms`;
    });
    return () => cancelAnimationFrame(animation);
  }, [kioskTimerTapTracker]);

  const kioskTimerReset = () => {
    triggerKioskInteraction(kioskTimerTapTracker === 0 ? 1 : 0);
  };

  const kioskTimerAnimationBlock = () => {
    return (
      <div ref={kTBRef4} className="kioskTimerAnimationTextSpan">
        <span ref={kTBRef} className={`kioskTimerAnimation`}></span>
        <p ref={kTBRef2} className={`kioskTimerAnimationText`}>
          <b>Are you still there?</b>
          <br />
          <strong>Tap screen to continue.</strong>
          <br />
          <small>
            <sub>Kiosk will reset shortly.</sub>
          </small>
          <br />
          <span ref={kTBRef3} className={`kioskTimerAnimationBar`}></span>
        </p>
      </div>
    );
  };

  return { kioskTimerReset, kioskTimerAnimationBlock };
}

export function useKioskError() {
  const navigate = useNavigate();
  const handleKioskError = (err) => {
    console.log("****useKioskError***", err);
    navigate("/error", { state: err });
  };

  return handleKioskError;
}
