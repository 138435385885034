export const constantVars = {
  /******************HEADS*****************/
  welcomeHead: {
    title: `Welcome to the `,
    title2: `Virtual Reception Desk`,
    tap: "Tap screen to begin",
  },

  launchHead: {
    title: "How may we help you?",
    back: "/",
  },

  demoHead: {
    title: "What product type do you want to demo?",
    prelink: "demo",
    back: "/launch",
  },

  meetingHead: {
    title: "Who's your meeting with?",
    subTitle: "Select the Rep. you have a meeting with below.",
    prelink: "meeting",
    back: "/launch",
  },

  findrepHead: {
    title: "Contact My Account Rep.",
    subTitle: "Find Account Representative by Name or by Country",
    back: "/launch",
  },

  nameregionHead: {
    title: "Who's your Account Rep.?",
    titleRegion2: "Select Account Representative",
    titleRegion: "Which Country?",
    subTitle: "Select an Account Representative",
    back: "/findrep",
    prelink: "findrep",
  },

  productsHead: {
    title: "Which product are you interested in?",
    prelink: "products",
    back: "/launch",
  },

  conferenceHead: {
    title: "Canon Conference Room Locator",
    subTitle: "Please follow the path below to the Conference Room location. ",
    subTitle2: "(Note: You are here __ICON__) ",
    back: "/launch",
  },

  contactHead: {
    title: "Contact __REP__",
    subTitle:
      "Use this form to notify __REP__ that you need assistance at __LOC__.",
    titleMeeting: "Check-in for Meeting with __REP__",
    subTitleMeeting:
      "Use this form to notify __REP__ that you are here for your scheduled meeting.",
    thankYouHead: "Thank You!",
    thankYouLong:
      "Please wait near this kiosk station and someone will be right with you.",
    thankYouSmall: "Please wait near by!",
    thankYouMeeting: "A notification has been sent to __REP__.",
    thankYouMeeting2: "Please wait near __LOC__.",
    back: "/",
  },

  directionsHead: {
    title: "__LOC__ Demo",
    subTitle:
      "Please follow the path below to the __LOC__ Product Demo location. ",
    subTitle2: "(Note: You are here __ICON__) ",
    back: "/demo",
  },

  gethelpHead: {
    title: "Get Help",
    subTitle: "Select US or International Assistance",
    back: "/launch",
  },

  /******************BLOCKS*****************/
  launchBlocks: [
    {
      heading: "Find Product Demo",
      subheading: "Directions to Product Demos",
      link: "demo",
    },
    {
      heading: "Check-in For Meeting",
      subheading: "Meeting Check-in",
      link: "meeting",
    },
    {
      heading: "Learn About Product",
      subheading: "Speak with Product Rep.",
      link: "products",
    },

    {
      heading: "Contact Account Rep.",
      subheading: "Find Account Rep.",
      emoji_array: ["jp", "us"],
      link: "findrep",
    },
    {
      heading: "Conference  Room  Locator",
      subheading: "Directions to Conference Rooms",
      link: "conferencerooms",
    },
    {
      heading: "Get Help",
      subheading: "Not sure?  Ask for Assistance.",
      // link: 'other/group/AM',
      link: "gethelp",
    },
  ],

  demoBlocks: [
    { heading: "Magnetic Resonance", subheading: null, link: "MR" },
    { heading: "Computed Tomography", subheading: null, link: "CT" },
    { heading: "Ultrasound", subheading: null, link: "UL" },
    { heading: "XR/R&F", subheading: null, link: "XR" },
    { heading: "Interventional Radiology", subheading: null, link: "IR" },
    { heading: "Molecular Imaging", subheading: null, link: "MI" },
    { heading: "HIT", subheading: null, link: "HIT" },
    { heading: "Service & Education", subheading: null, link: "SE" },
    // { heading: "Other", subheading: null, link: "OT" },
  ],

  findrepBlocks: [
    {
      heading: "Find By Name",
      subheading: "Search for your Rep. by  Name",
      link: "findrep/byname",
    },
    {
      heading: "Find By Country",
      subheading: "Search for your Rep. by Country/Location",
      emoji_array: ["br", "ca", "jp", "gb", "us"],
      link: "findrep/byregion",
    },
  ],

  productsBlocks: [
    { heading: "Magnetic Resonance", subheading: null, link: "MR" },
    { heading: "Computed Tomography", subheading: null, link: "CT" },
    { heading: "Ultrasound", subheading: null, link: "UL" },
    { heading: "XR/R&F", subheading: null, link: "XR" },
    { heading: "Interventional Radiology", subheading: null, link: "IR" },
    { heading: "Molecular Imaging", subheading: null, link: "MI" },
    { heading: "HIT", subheading: null, link: "HIT" },
    { heading: "Service & Education", subheading: null, link: "SE" },
    // { heading: 'Other Product', subheading: 'Get Help!', link: 'AM' },
  ],

  gethelpBlocks: [
    {
      heading: "Canon Ambassador",
      subheading: "Get help from US Canon Ambassador",
      emojis: "🇧🇷🇨🇦🇯🇵🇬🇧🇺🇸",
      emoji_array: ["us"],
      link: "other/group/AM",
    },
    {
      heading: "International Ambassador ",
      subheading: "Get help from International  Ambassador",
      emojis: "🇧🇷🇨🇦🇯🇵🇬🇧🇺🇸",
      emoji_array: ["br", "ca", "jp", "gb"],
      link: "other/group/IA",
    },
  ],

  /******************MISC*****************/
  directionCoordinates: {
    1: {
      MR:
        "M131,497 q85,70 124,71 q91,10 181,-15 q110,-18 110,-18 q120,-1 167,62 q41,34.5 82,19 q21,-9 23,-36",
      CT: "M133,490 q54,63.5 167,-45 q94,-94 121,-32",
      UL:
        "M127,490 q90,56.5 180,63 q143.5,13.5 287,-23 q136,-49 192,-93 q100,-37 175,86 q39,45 149,73",
      XR: "M54,361 q230,7 460,-36 q42.5,13.5 85,-23 q12,-62.5 24,-175",
      SE:
        "M128,497 q16,79 57,73 q31,-3 168,-136 q74,-76 221,-141 q44,-34 34,-58 q-16,-29 -58,-20",
      IR: "M58,364 q-6,-122 54,-166",
      MI: "M53,372 q191.5,19.5 383,-11 q108,-20 222,-9",
      HIT:
        "M128,494 q45,87 79,76 q64,-35 175,-26 q141,21 201,-3 q114,-51 246,-172",
    },
    2222: {
      MR: "M263,698 q154,13 300,-2 q77,-3 156,-29 q62,-22 97,-90",
      CT: "M261,683 q30,-147 64,-245 q51,-105 95,-25",
      UL:
        "M265,698 q15,-109 50,-126 q25,-20 111,-16 q210,-17 357,-123 q160,-41 322,159",
      XR:
        "M263,686 q15,-17.5 30,-85 q14.5,-34.5 29,-119 q26,-45 61,-69 q29,-49 97,-104 q39,-36 81,-71 q19,-12 38,-74 q9,-20 21,-40",
      SE: "M263,686 q41,-254 150,-295 q168,-63 190,-141 q-5,-33 -48,-35",
      IR: "M261,685 q46,-222 12,-239 q-60,-37 -82,-74 q-42.5,-60.5 -65,-163",
      MI:
        "M263,681 q17,-31.5 42,-119 q30,-46 60,-142 q26,-36 89,-34 q103,11 206,-28",
      HIT: "M265,700 q-8,-139 48,-138 q136,29 231,-1 q179,-66 287,-191",
    },
    2: {
      MR: "M518,700 q78,1 137,-13 q-15,6 64,-20 q74,-25 97,-90",
      CT: "M511,690 q-32,-119 -17,-176 q6,-106 -16,-119 q-27,-26 -50,18",
      UL: "M515,694 q103,20 247,30 q85,3 155,-4 q102,-4 133,-115 q9,-45 47,-21",
      XR:
        "M516,684 q-13,-40 -18,-93 q0,-38 -2,-116 q2,-36 22,-77 q26,-46 31,-84 q15,-32 25,-66 q18,-50 25,-84 q9,-20 21,-40",
      SE: "M505,684 q-8.5,-117 -17,-284 q15,-77 73,-114 q41,-34 -5,-69",
      IR:
        "M514,681 q-7.5,-79.5 -20,-223 q-11,-84 -84,-89 q-117,0 -220,-43 q-36.5,-31.5 -73,-113",
      MI: "M517,695 q-16.5,-110 -33,-270 q6,-31 28,-44 q77,-33 151,-24",
      HIT: "M511,685 q-31,-57 37,-130 q116,-74 285,-184",
    },
    3: {
      MR: "M767,687 q-3,-3 39,-45 q21,-43 10,-63",
      CT:
        "M755,682 q-53,-154 -87,-145 q-146,48 -162,-12 q-15,-113 -23,-125 q-28,-48 -60,20",
      UL: "M760,693 q110,25 183,33 q83,-22 93,-73 q4,-26 6,-63 q9,-37 52,-15",
      XR: "M755,695 q-57,-129 -71,-151 q-36,-46 -72,-142 q-10,-118 6,-276",
      SE: "M759,694 q-71,-110.5 -142,-271 q-31.5,-78 -63,-206",
      IR:
        "M760,696 q-53.5,-79.5 -107,-209 q-36,-116 -61,-113 q-62,-9 -116,-9 q-164,-15 -317,-43 q-40,-18 -42,-113",
      MI: "M761,692 q-72.5,-115 -145,-280 q1,2 2,-46 q18,-27 40,-14",
      HIT: "M761,692 q-95,-133 -81,-187 q50,-87 147,-134",
    },
    4: {
      MR: "M1118,690 q-66,24 -114,-122 q-17,-58 -86,-57 q-49,-4 -99,61",
      CT:
        "M1120,696 q-73,19 -81,-29 q-14,-81 -46,-122 q-82,-143 -205,-107 q-109,57 -238,97 q-59,5 -66,-63 q14,-64 -9,-87 q-39,-15 -48,32",
      UL: "M1115,695 q-112,-52 -91,-112 q25,-40 71,-12",
      XR:
        "M1125,692 q-35,13 -70,-24 q-50,-41.5 -100,-133 q-16.5,-68.5 -33,-187 q-8,-37 -48,-63 q-22,-4 -126,-29 q-62.5,-5 -114,-58 q-14,-16 -17,-80",
      SE: "M1123,693 q-234,-167 -399,-372 q-79,-73 -171,-106",
      IR:
        "M1119,695 q-40.5,1.5 -81,-47 q-23.5,-24.5 -47,-99 q-4,-57 -8,-164 q-1,-55 -60,-118 q-65,-58 -154,-39 q-99,57 -206,76 q-187.5,39.5 -375,29 q-29.5,10.5 -59,-29 q-7.5,-22.5 -15,-95",
      MI:
        "M1120,698 q-124.5,29 -249,8 q-72.5,-14.5 -145,-79 q-41,-54 -82,-158 q-12.5,-20 -25,-90 q8,-29 35,-31",
      HIT:
        "M1122,693 q-70,5 -81,-33 q-11,-94 -95,-139 q-67,-32 -114,-96 q-18,-22 -5,-50",
    },
    5: {
      MR: "M550,64 q32,184 64,318 q50.5,137 101,224 q40,38 80,26 q24,-6 26,-59",
      CT: "M551,76 q58,99 44,171 q-2,46 -104,94 q-55,35 -64,78",
      UL: "M548,63 q153,261 279,375 q46,48 278,152",
      XR: "M547,61 q23,51 76,52",
      SE: "M550,61 q44,38 46,108 q-6,30 -46,47",
      IR:
        "M549,70 q47,105 36,166 q-62,61.5 -124,73 q-160,29 -303,20 q-29,-21 -41,-122",
      MI: "M550,65 q38.5,144 77,238 q20.5,53.5 41,57",
      HIT: "M551,71 q52,118 123,207 q95,121 157,98",
    },
    6: {
      MR:
        "M50,364 q202,44 192,112 q3,96 102,78 q229,-44 330,8 q9,11 50,53 q34,28 78,13 q33,-25 16,-51",
      CT: "M50,365 q128,-31 260,-21 q104,21 114,69",
      UL:
        "M56,368 q190,-4 217,43 q30,29 40,126 q30,75 401,-72 q67,-29 90,-35 q57,-31 169,34 q85,66 127,115",
      XR: "M123,483 q6,116 120,69 q117,-107 241,-227 q82,-99 136,-203",
      SE: "M57,365 q418,-3 492,-37 q41,-16 50,-53 q4,-46 -41,-59",
      IR: "M125,482 q23,112 118,69 q88,-104 -58,-198 q-68,-31 -65,-146",
      MI: "M127,485 q33,128 122,54 q138,-189 317,-225 q69,-5 102,43",
      HIT: "M48,366 q294,-12 596,-73 q86,-22 110,58 q40,79 75,30",
    },
  },

  conferenceRoomsCoordinates: {
    1: "M128,492 q-30,81 64,56 q123,-67 211,-171 q25,-36 134,-80 q118,-55 218,-71 q98,-25 185,26",
    2222: "M264,696 q43,-85 49,-210 q12,-72 71,-101 q87,-24 166,-42 q55,-18 115,-53 q61,-34 133,-60 q75,-2 157,34",
    2: "M507,699 q-26,-117 -10,-284 q71,-100 163,-148 q59,-39 130,-39 q71,-1 155,30",
    3: "M759,695 q-5,-54 54,-86 q30,-21 38,-92 q19,-107 91,-263",
    4: "M1121,697 q-80,-37 -124,-154 q-12,-19 -24,-88 q-0.5,-37 -1,-124 q-4,-35 -16,-61",
    5: "M550,63 q30,139 107,172 q94,22 164,-4 q63,-11 116,19",
    6: "M61,364 q246,-2 461,-40 q123,-29 226,-85 q95,-26 186,22",
  },

  misc: {
    alphabet: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    min_count_to_show_letters: 10,
    emojis_icons: {
      youAreHere: "📍",
      yourDestination: "❌",
    },
  },
};
